import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import Verbatim from '../components/blockContent/verbatim/verbatim';
import MainPlaceholderVideo from '../components/blockContent/mainPlaceholderVideo';
import TextWithTitle from '../components/textWithTitle';
import PeopleList from '../components/blockContent/people/peopleList';
import FullwidthImgTitleCol from '../components/blockContent/fullwidthImgTitleCol';
import BackToTop from '../components/navigation/backToTop';

const pageRse = ({ pageContext, data, intl }) => {
  const page = data.allContentfulPageRse.nodes[0];

  return (
    <>
      <Layout
        pageContext={pageContext}
        headerCssClass="bkg-color2"
        mainCssClass="page-rse"
        activeMenuLink="rse"
      >
        <SEO
          title={page.seo?.metaTitle}
          titleOnglet={page.seo?.title}
          description={page.seo?.metaDescription}
          imageLink={page.seo?.socialImage?.file?.url}
          pageLink={page.seo?.slug}
        />

        {/* HeroTitle */}
        <HeroTitle subtitle={page.surTitre} title={page.titre} />

        {/* START CUSTOM BLOCKS */}
        {page.customBlocks &&
          page.customBlocks?.map((block, i) => {
            switch (block.__typename) {
              case 'ContentfulBlocCitation':
                return <Verbatim verbatim={block} key={i} />;

              case 'ContentfulBlocPersonnes':
                return (
                  <PeopleList
                    peopleList={block.people}
                    title={block.title}
                    key={i}
                  />
                );

              case 'ContentfulVisuelMajeur':
                return <MainPlaceholderVideo media={block} key={i} />;

              case 'ContentfulTexteAvecUnTitre':
                return (
                  <TextWithTitle
                    key={i}
                    title={block.title}
                    text={
                      block.childContentfulTexteAvecUnTitreTextTextNode
                        .childMarkdownRemark.html
                    }
                    fullWidth
                  />
                );

              case 'ContentfulImageDeFondTitreTexte3ColonnesLien':
                return (
                  <FullwidthImgTitleCol
                    key={i}
                    title={block.titre}
                    downloadLink={block.downloadLink}
                    bkgDesktop={block.backgroundImageDesktop}
                    bkgMobile={block.backgroundImageMobile}
                    cols={block.colonnes}
                  />
                );

              default:
                break;
            }
          })}
        {/* END CUSTOM BLOCKS */}

        {/* BackToTop */}
        <BackToTop />
      </Layout>
    </>
  );
};

export const query = graphql`
  query contentfulPageRse($language: String!) {
    allContentfulPageRse(
      filter: { node_locale: { eq: $language } }
      limit: 1
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        titre
        contentful_id
        surTitre
        seo {
          metaTitle
          metaDescription
          socialImage {
            file {
              url
            }
          }
          title
          slug
        }
        customBlocks {
          __typename
          ... on Node {
            ... on ContentfulBlocCitation {
              id
              verbatim {
                childMarkdownRemark {
                  html
                }
              }
              author
              status
            }
            ... on ContentfulBlocPersonnes {
              id
              title
              people {
                id
                name
                linkedin
                job {
                  label
                }
                picture {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  description
                }
                otherlinks {
                  label
                  url
                  linkType
                }
              }
            }
            ... on ContentfulVisuelMajeur {
              visuel {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
              backgroundVideo {
                file {
                  url
                }
              }
              videoLink
            }
            ... on ContentfulTexteAvecUnTitre {
              id
              title
              childContentfulTexteAvecUnTitreTextTextNode {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulImageDeFondTitreTexte3ColonnesLien {
              id
              backgroundImageDesktop {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
              backgroundImageMobile {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                description
              }
              downloadLink
              titre
              colonnes {
                title
                childContentfulTexteAvecUnTitreTextTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default injectIntl(pageRse);
