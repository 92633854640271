import React from 'react';

function verbatim({ verbatim }) {
  return (
    <figure className="block-content content is-fullwidth bkg-color1 has-text-centered pb-5 mb-0">
      <blockquote
        dangerouslySetInnerHTML={{
          __html: verbatim.verbatim.childMarkdownRemark.html,
        }}
      />
      <figcaption className="is-text-indication mb-6">
        <strong>{verbatim.author}</strong>
        {verbatim.status}
      </figcaption>
    </figure>
  );
}

export default verbatim;
