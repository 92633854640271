import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

import './popinVideo.scss';

class PopinVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      srcVideo: '',
    };
  }

  componentDidMount() {
    this.setState({
      srcVideo: `https://www.youtube.com/embed/${this.props.idVideo}?rel=0&enablejsapi=1`,
    });
  }

  // Reset Video when popin closed
  handleRefreshIframe = videoLink => {
    this.setState({
      srcVideo: '',
    });
    setTimeout(() => {
      this.setState({
        srcVideo: videoLink,
      });
    }, 100);
  };

  render() {
    const { idVideo, show, intl } = this.props;
    var handleShowPopin = this.props.handleShowPopin;

    return (
      <>
        <Helmet>
          <body className={`${show ? 'is-clipped' : ''}`} />
          <script type="text/plain" class="optanon-category-C0005">{`
              "use strict"; document.addEventListener('DOMContentLoaded', function(){if (window.hideYTActivated) return; let onYouTubeIframeAPIReadyCallbacks=[]; for (let playerWrap of document.querySelectorAll(".hytPlayerWrap")){let playerFrame=playerWrap.querySelector("iframe"); let tag=document.createElement('script'); tag.src="https://www.youtube.com/iframe_api"; let firstScriptTag=document.getElementsByTagName('script')[0]; firstScriptTag.parentNode.insertBefore(tag, firstScriptTag); let onPlayerStateChange=function(event){if (event.data==YT.PlayerState.ENDED){playerWrap.classList.add("ended");}else if (event.data==YT.PlayerState.PAUSED){playerWrap.classList.add("paused");}else if (event.data==YT.PlayerState.PLAYING){playerWrap.classList.remove("ended"); playerWrap.classList.remove("paused");}}; let player; onYouTubeIframeAPIReadyCallbacks.push(function(){player=new YT.Player(playerFrame,{events:{'onStateChange': onPlayerStateChange}});}); playerWrap.addEventListener("click", function(){let playerState=player.getPlayerState(); if (playerState==YT.PlayerState.ENDED){player.seekTo(0);}else if (playerState==YT.PlayerState.PAUSED){player.playVideo();}});}window.onYouTubeIframeAPIReady=function(){for (let callback of onYouTubeIframeAPIReadyCallbacks){callback();}}; window.hideYTActivated=true;});
          `}</script>
        </Helmet>
        <div className={`modal ${show ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <figure className="image is-16by9">
              {/* https://maxl.us/hide-related */}
              <div className="hytPlayerWrapOuter">
                {' '}
                <div className="hytPlayerWrap">
                  {' '}
                  <iframe
                    className="has-ratio"
                    width="100%"
                    height="auto"
                    src={this.state.srcVideo}
                    title="Youtube Video Player"
                  ></iframe>{' '}
                </div>
              </div>
            </figure>
          </div>
          <button
            className="modal-close-custom is-large"
            aria-label={intl.formatMessage({
              id: 'close_btn',
            })}
            onClick={e => {
              handleShowPopin(e);
              this.handleRefreshIframe(this.state.srcVideo);
            }}
          >
            <FormattedMessage id="close_btn" />{' '}
            <span className="icon-close-circle"></span>
          </button>
        </div>
      </>
    );
  }
}

export default injectIntl(PopinVideo);
