import React from 'react';
import AnimateLink from '../../animateLink';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { injectIntl } from 'gatsby-plugin-intl';

function people({ people, intl }) {
  const firstname = people.name.split(' ')[0];
  const lastname = people.name.split(' ')[1];
  const image = getImage(people.picture);

  return (
    <div className="column col-people columns is-flex-direction-column">
      <div>
        <h3 className="title is-2 is-uppercase">
          {firstname} <br />
          {lastname}
        </h3>
        <p className="is-uppercase mb-3">{people.job.label}</p>
        {people.linkedin && (
          <AnimateLink
            externalLink={true}
            url={people.linkedin}
            cssClassLink="link-menu-ext is-text-indication is-uppercase"
            ariaLabel={`Linkedin ${intl.formatMessage({
              id: 'of',
            })} ${firstname} ${lastname} ${intl.formatMessage({
              id: 'new_tab',
            })}`}
          >
            Linkedin <span className="icon-icon-menu" aria-hidden={true}></span>
          </AnimateLink>
        )}
        {people.otherlinks &&
          people.otherlinks?.map((link, i) => {
            return (
              <AnimateLink
                externalLink={link.linkType}
                url={`${
                  link.url.indexOf('@') > 0 ? 'mailto:' + link.url : link.url
                }`}
                cssClassLink={`is-text-indication is-uppercase ${
                  link.linkType ? 'link-menu-ext' : ''
                }`}
                key={i}
                ariaLabel={
                  link.url.indexOf('@') > 0
                    ? `Email ${intl.formatMessage({
                        id: 'of',
                      })} ${firstname} ${lastname} (popup)`
                    : `${link.label} ${intl.formatMessage({
                        id: 'new_tab',
                      })}`
                }
              >
                {link.label} <span className="icon-icon-menu"></span>
              </AnimateLink>
            );
          })}
      </div>
      {image && (
        <GatsbyImage image={image} as="figure" alt={people.name || ''} />
      )}
    </div>
  );
}

export default injectIntl(people);
