import React from 'react';
import People from './people';

function PeopleList({ title, peopleList }) {
  return (
    <>
      <section className="block-content is-fullwidth block-people">
        <h2 className="title is-3">{title}</h2>
        <div className="columns row-people">
          {peopleList?.map((people, i) => (
            <People key={i} people={people} />
          ))}
        </div>
      </section>
    </>
  );
}

export default PeopleList;
