import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import TextWithTitle from '../textWithTitle';
import AnimateLink from '../animateLink';

function FullwidthImgTitleCol({
  title,
  downloadLink,
  cols,
  bkgDesktop,
  bkgMobile,
}) {
  const imageMobile = getImage(bkgMobile);
  const imageDesktop = getImage(bkgDesktop);

  return (
    <section className="block-content bkg-col is-fullwidth bkg-color1">
      {imageDesktop && (
        <GatsbyImage
          as="figure"
          className="is-fullwidth-image bkg-col_img is-hidden-touch"
          image={imageDesktop}
          alt={bkgDesktop.description || ''}
        />
      )}

      {imageMobile && (
        <GatsbyImage
          as="figure"
          className="is-fullwidth-image bkg-col_img is-hidden-desktop"
          image={imageMobile}
          alt={bkgMobile.description}
        />
      )}

      <div className="bkg-col_content">
        <div className="columns bkg-col_content_header is-justify-content-space-between">
          <h2 className="column title is-3 mb-0">{title}</h2>

          {downloadLink && (
            <AnimateLink
              url={downloadLink}
              externalLink={true}
              cssClassLink="column link download"
            >
              <FormattedMessage id="project.dl" />{' '}
              <span className="icon-download"></span>
            </AnimateLink>
          )}
        </div>

        <div className="columns is-justify-content-center bkg-col_content_cols">
          {cols?.map((col, i) => (
            <TextWithTitle
              key={i}
              title={col.title}
              text={
                col.childContentfulTexteAvecUnTitreTextTextNode
                  .childMarkdownRemark.html
              }
              fullWidth
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default FullwidthImgTitleCol;
